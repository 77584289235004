<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Datos del alumno</span>
                    </h3>
                    <div class="card-toolbar">
                        <div class="row">
                            <div class="col-6 text-center">
                                <i class="fas fa-user fa-3x" :style="{'color': selectedItem.traininginfo.status.color}"
                                   :title="selectedItem.traininginfo.status.description"></i>
                            </div>
                            <div class="col-6">
                                <a
                                        @click="goList"
                                        class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                                >
                                    Volver
                                </a>
                            </div>
                            <div class="col-6 text-center">
                                {{ selectedItem.traininginfo.status.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pb-10 pb-2">
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Rut</div>
                            <div>{{ selectedItem.fullRutWithHypend }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Nombre y apellidos</div>
                            <div>{{ selectedItem.name }}&nbsp;{{ selectedItem.last_name }}</div>
                        </div>
                    </div>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Empresa</div>
                            <div>{{ selectedItem.order.enterprise.razon_social_empresa }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Training</div>
                            <div>{{ selectedItem.order.training.name }}</div>
                        </div>
                    </div>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Correo empresa</div>
                            <div>{{ selectedItem.email }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Teléfono empresa</div>
                            <div>{{ selectedItem.phone }}</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail mb-2">Selección</div>
                            <div v-if="selectedItem.order.training.courses.length > 0">
                                <div v-for="course in selectedItem.order.training.courses" :key="course">
                                    <i class="fa fa-check" v-if="isCourseSelect(course.id)" style="color: green"></i>
                                    <i class="fa fa-check" v-else style="color: gray"></i>
                                    <label class="ml-2">{{ course.name }}</label>
                                </div>
                            </div>
                            <div v-else>Sin cursos</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

    export default {
        name: "StudentDetail",
        data() {
            return {};
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Alumnos", route: "students"},
                {title: "Datos del estudiante"},
            ]);
        },
        methods: {
            goList() {
                this.$router.push("students");
            },
            isCourseSelect(courseId){
                return this.selectedItem.course_selection.find(course => course.id == courseId) != null
            }
        },
        computed: {
            selectedItem() {
                return this.$store.state.students.selectedStudent;
            },
        },
    };
</script>

<style scoped></style>
